/*
| ===================================================
| This File is for the home page
| ===================================================
*/
<template>
  <v-layout wrap justify-start>
    <v-flex xs12>
      <v-layout wrap justify-center>
        <v-flex xs12 pb-12>
          <v-img src="@/assets/home-green.svg" cover height="30vh">
            <v-layout wrap justify-start fill-height>
              <v-flex xs12 text-center align-self-center>
                <div class="heading">
                  Cauzioni
                </div>
              </v-flex>
            </v-layout>
          </v-img>
        </v-flex>
        <v-flex xs12 sm11 md9 lg6 xl4 px-2 py-12>
          <div class="normal-text">
            <span class="bold">CB Digital Cauzioni</span> è la piattaforma del gruppo Consulbrokers Spa che mette a disposizione i seguenti servizi:
            <ul class="pt-5">
              <li>Cauzioni provvisorie e definitive</li>
              <li>Rimborso IVA</li>
              <li>Cauzione per l’anticipazione sull’esecuzione dei lavori</li>
              <li>Cauzione per la rata di saldo</li>
              <li>Erogazione contributi</li>
              <li>Urbanizzazioni</li>
              <li>Capacità finanziaria autotrasportatori Iscrizione/ Rinnovo albo gestori ambientali Gestione rifiuti</li>
              <li>Garanzie per stoccaggio carburanti (Accise) Legge 210</li>
              <li>Locazioni</li>
              <li>Garanzie di pagamento</li>
              <li>Garanzie fra privati</li>
            </ul>
          </div>
          <v-flex xs12 pa-10 text-center>
            <a href="https://cauzioni.cbdigital.tech/" target="_blank">
              <v-btn color="primary" dark :ripple="false" depressed>
                <span class="text-capitalize">Vai al Servizio</span>
              </v-btn>
            </a>
          </v-flex>
          <v-flex xs12 text-center>
              <img src="icons/Partner-01.jpg" width="150"> 
              <img src="icons/Partner-02.jpg" width="150">
              <img src="icons/Partner-03.jpg" width="150">
              <img src="icons/Partner-05.jpg" width="150">
              <img src="icons/Partner-06.jpg" width="150">
              <img src="icons/Partner-07.jpg" width="150">
              <img src="icons/Partner-08.jpg" width="150">
              <img src="icons/Partner-09.jpg" width="150">
              <img src="icons/Partner-10.jpg" width="150">
              <img src="icons/Partner-11.jpg" width="150">
              <img src="icons/Partner-12.jpg" width="150">
              <img src="icons/Partner-13.jpg" width="150">
              <img src="icons/Partner-14.jpg" width="150">
              <img src="icons/Partner-15.jpg" width="150">
              <img src="icons/Partner-16.jpg" width="150">
              <img src="icons/Partner-17.jpg" width="150">
              <img src="icons/Partner-18.jpg" width="150">
              <img src="icons/Partner-19.jpg" width="150">
              <img src="icons/Partner-20.jpg" width="150">
              <img src="icons/Partner-21.jpg" width="150">
              <img src="icons/Partner-22.jpg" width="150">
          </v-flex>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>

export default {
  name: "Guarantees",
  components: {
    
  }
}
</script>